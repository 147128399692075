// Colours
$white: #ffffff;
$yellow: #ffca00;
$blue:#05B6FF;
$copy-gray: #4c4c4c4c;
$gray: #444444;
$brand-gray: #7a7a7a;
$medium-gray: #434343;
$light-gray: #696969;
$black: #000000;
$yash:#f7f7f7;
$mercury:#E4E4E4;
$light-blue:#F3FBFF;
$green:#54B329;
$light-green:#77C550;
$error : #FF0024;


// Font family

$roboto: "Roboto", Helvetica, Arial, sans-serif;
$roboto-m: 'Roboto-medium';
$roboto-b: 'Roboto-bold';
$roboto-l: 'Roboto-light';
$roboto-bl: 'Roboto-black';