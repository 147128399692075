.process {

    .col-md-3 {
        @include mobile {
            margin-bottom: 15px;
        }
    }
    .card {
        background-color: $white;
        box-shadow: none;

        img {
            max-width: 60px;
        }

        .card-body {
            border: none;
        }

        &:hover {
            background-color: $light-green;

            h4 {
                color: $white;
            }

            .card-body {
                background-color: transparent;
                color: $white;
                border: none;
            }
        }

        .img-wrapper {
            padding: 15px 0;
            background-color: $green;
        }
    }

  

    h4 {
        margin-bottom: 15px
    }
}